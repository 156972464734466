<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexCharts
                v-if="!isLoading"
                height="440px"
                :options="chartOptionsMoisture('temp')"
                :series="moistureSeries"
            />
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import DateRangeInput from '@/components/DateRangeInput'
import TreeChartMixin from '@/components/asset-types/tree-moisture/mixins/TreeChartMixin'

export default {
    name: 'AssetTreeChartsView',
    components: {
        ApexCharts,
        DateRangeInput,
        VSpinner,
    },
    mixins: [TreeChartMixin],
}
</script>

<i18n>
{
    "en": {
        "temperature": "Temperature",
        "moisture": "Moisture",
        "top": "Top",
        "bottom": "Bottom"
    },
    "de": {
        "temperature": "Temperatur",
        "moisture": "Feuchtigkeit",
        "top": "Oben",
        "bottom": "Unten"
    },
    "fr": {
        "temperature": "Température",
        "moisture": "Humidité",
        "top": "Haut",
        "bottom": "Bas"
    },
    "it": {
        "temperature": "Temperatura",
        "moisture": "Umidità",
        "top": "Sopra",
        "bottom": "Sotto"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-holder {
        display: block;
    }

    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}
</style>
